/* eslint-disable react/no-array-index-key */
import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

const SectionWrapper = styled.section`
  background-color: ${(props) => props.theme.color.face.secondary};
`

const Content = styled(ParseContent)`
  p {
    @media (min-width: 992px) {
      line-height: ${(props) => props.theme.font.size[16]};
    }
  }
`

// TODO: Kijken of dit korter kan
const InfoImageBlock = styled(Plaatjie)`
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
`

// TODO: Kijken of dit korter kan
const SocialImageBlock = styled(Plaatjie)`
  width: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
`

interface ContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Contact
}

const Contact: React.FC<ContactProps> = ({ fields }) => (
  <SectionWrapper>
    <div className="container pb-lg-5">
      <div className="row mb-lg-3">
        <div className="col-md-6 col-lg-4 col-12 mb-5 mt-sm-0 mt-5">
          <Content content={fields.description} />
        </div>
        <div className="col-md-6 col-lg-4 col-12 mb-5">
          {fields?.info?.map((edge, index: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="d-flex justify-content-start align-items-center mb-1"
            >
              <InfoImageBlock image={edge?.image} alt="" />
              <ParseContent content={edge?.description} className="pl-4" />
            </div>
          ))}
        </div>
        <div className="col-lg-4 col-12 d-flex align-items-center mb-5 justify-content-lg-end justify-content-start">
          {fields?.social?.map((edge, index: number) => (
            <div key={index}>
              <Link to={edge?.link?.url || '/404'} target="_blank">
                <SocialImageBlock
                  image={edge?.image}
                  alt=""
                  className="ml-lg-3 mr-lg-0 mr-3"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  </SectionWrapper>
)

export default Contact
